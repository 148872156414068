<template>
  <div>
    loading
  </div>
</template>

<script>
import imApi from "../utils/imapi";
import { setLocalStorage } from "../utils/local-storage";

export default {
  name: "Auth",
  data() {
    return {
      imApi: new imApi()
    };
  },
  created() {
    this.imApi.setBaseUrl(this.$imAPI);

    this.auth();
  },
  methods: {
    auth() {
      let url = this.$authUrl;
      let token = this.$route.query.token;
      this.imApi
        .ThirdPartAuth(url, token)
        .then(data => {
          if (data.access_token) {
            setLocalStorage({
              Authorization: data.access_token,
              Expire: data.profile.expires_at
            });

            let uid = data.profile.sid;
            this.imApi
              .GetUserInfo(uid)
              .then(data => {
                setLocalStorage({
                  UserInfo: JSON.stringify(data.userinfo),
                  SiteIds: data.serviceIds
                });
                this.loading = false;
                this.$router.push({ path: this.redirect || "/" });
              })
              .catch(err => {
                console.log(err);
              });
          }
        })
        .catch(response => {
          this.$notify.error({
            title: "失败",
            message: response.data
          });
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
